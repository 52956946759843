// Breakpoints
$large-screen: "all and (min-width : 992px)";
$small-screen: "all and (max-width : 991px)";

// Colors
$primary-green: #51c380;
$primary-text: #202020;
$secondary-text: #9b9b9b;
$primary-text-inverted: #ffffff;

$primary-background: #f9f9f9;
$secondary-background: #ffffff;

$primary-text-form: #4a4a4a;
$error-text-form: #e36171;

$cookie-consent-background: #333333;
$cookie-consent-heading-color: #eb5b2b;
$cookie-consent-button-color: $cookie-consent-heading-color;
