.spinner-wrapper {
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
}

.spinner {
  height: 40px;
  width: 40px;
  animation: spinner-1-1 3.8s linear infinite;
  background: #f2f2f2;
  border-radius: 50%;
  margin: 0 auto;

  .redirect-email-wrapper & {
    background: #a259ff;
    &::after {
      background: #6d22e9;
    }
    span {
      &::after {
        border: 8px solid #f2f2f2;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 32px;
    width: 32px;
    background: #c1e3d0;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 40px;
    width: 40px;
    clip: rect(0, 40px, 40px, 20px);
    -webkit-animation: spinner-1-2 1.2s linear infinite;
    animation: spinner-1-2 1.2s linear infinite;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 40px;
      width: 40px;
      clip: rect(0, 40px, 40px, 20px);
      border: 8px solid #57ca65;
      border-radius: 50%;
      -webkit-animation: spinner-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1)
        infinite;
      animation: spinner-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
  }
}

@keyframes spinner-1-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-1-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
}

@keyframes spinner-1-3 {
  0% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-160deg);
  }
  100% {
    transform: rotate(140deg);
  }
}
