@import "../../styles/variables.scss";
// TODO: This still contains a bunch of styles that are also used by QuestionFormPage
.form-screen {
  h2 {
    font-size: 22px;
    color: $primary-text-form;
    margin-bottom: 15px;
  }
  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__button {
    font-size: 16px;
    height: 50px;
    background: $primary-green;
    border-radius: 5px;
    margin: 0;
    width: 100%;
    display: block;
    padding: 0;

    &.form-screen__button--disabled {
      background: #c1e3d0;
      opacity: 1 !important;
    }
  }

  &__form-section {
    @keyframes form-items {
      0% {
        transform: translate3d(0, -100%, 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &__block {
    display: block;
    width: 100%;
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  &__error {
    margin-top: 20px;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
  }
  &__error-text {
    background: $error-text-form;
    display: inline;
    padding: 6px 16px;
    border-radius: 5px;
  }

  @media #{$large-screen} {
    &__button {
      width: 330px;
    }
    .container {
      display: flex;
      max-width: 100%;
    }
    &__form-section {
      background: $primary-background;
      padding: 40px 60px;
      max-width: inherit;
      width: 67vw;
      margin-left: 33vw;
    }
    &__block {
      margin-bottom: 15px;
    }
    &__blocks-group {
      display: flex;
      justify-content: space-between;
      .form-screen__block {
        width: calc(50% - 8px);
      }
    }
  }
}
