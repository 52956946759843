@import "../../styles/variables.scss";

.avatar-selector {
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 18px;
    img {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      max-width: 140%;
      max-height: 140%;
      background: #dee2e6;
      object-fit: cover;
    }
  }
  &__text {
    font-size: 15px;
    font-weight: normal;
    color: $secondary-text;
    span {
      display: block;
      color: $primary-green;
      font-weight: bold;
      line-height: 1.6;
    }
  }
  &__sub-heading {
    color: $secondary-text;
    font-weight: 400;
    margin-top: 10px;
    &::after {
      content: "";
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 1px;
      width: 11px;
      height: 10px;
      background: url("../../../assets/images/arrow.svg") no-repeat center
        center/contain;
    }
  }
  @media #{$large-screen} {
    padding: 20px 30px;
    cursor: pointer;
    .form-screen__text {
      width: calc(100% - 80px);
    }
  }
}
