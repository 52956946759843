@import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: Montserrat, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  overflow-x: hidden;
  color: #fff;
}

.overflow-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.Toastify__toast--error {
  //background: $error-text-form;
}
