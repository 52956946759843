@import "../../styles/variables.scss";

.popup-cv--form {
  @media #{$large-screen} {
    margin-left: 33vw !important;
  }
}

.popup-cv {
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .popup__title {
    small {
      font-size: 14px;
    }
  }
  &__error {
    text-align: center;
    .popup__title {
      color: $error-text-form;
      margin-bottom: 20px;
    }
    .error__text {
      font-size: 14px;
      color: $secondary-text;
    }
    .popup__tab-button {
      font-size: 16px;
    }
  }
  &__close {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto 15px;
    padding: 0;
    border: none;
    transition: all 0.3s ease;
  }
  @media #{$large-screen} {
    margin-left: 0px;
    .popup__content {
      max-width: 340px;
      padding: 40px 20px;
    }
    .popup__upload {
      > div,
      .popup__upload-item {
        width: 100%;
      }
    }
  }
}
