.secret-index-page {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.index-page-item {
  display: flex;
  width: 400px;
  justify-content: space-between;
}
