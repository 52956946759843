@import "../../styles/variables.scss";

.applied-screen {
  background: linear-gradient(45deg, #4cbc9d 0%, #57ca65 100%);
  text-align: center;
  padding: 20px 16px 30px;
  min-height: 100vh;
  h2 {
    color: $primary-text-form;
    font-size: 22px;
    font-weight: bold;
  }
  span {
    display: block;
    font-size: 16px;
    color: $secondary-text;
  }
  .percentage {
    color: $primary-green;
    font-weight: bold;
  }
  &__title {
    margin-bottom: 65px;
    span {
      font-weight: bold;
      font-size: 32px;
      color: #fff;
      &::after {
        content: "";
        position: relative;
        display: block;
        height: 3px;
        width: 120px;
        margin: 10px auto 10px;
        background: #ececec;
      }
    }
  }
  .confirmation-animated-header {
    animation: confirmation-header 0.3s forwards;
  }
  @keyframes confirmation-header {
    0% {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  @media #{$large-screen} {
    padding: 25px 15px 47px;
    .container {
      max-width: 520px;
      min-height: initial;
    }
    .job-screen__title {
      margin: 0;
      min-height: initial;
      height: auto;
      width: auto;
      padding-bottom: 24px;
      position: relative;
    }
  }
}

.profile {
  &__info {
    position: relative;
    margin-top: 40px;
    padding: 1px;
    animation: bounceInDown 1s;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      left: 0;
      top: 0;
      height: 75%;
      z-index: 1;
      box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.3);
    }
    &-wrapper {
      position: relative;
      padding: 0 20px 30px;
      background: url("../../../assets/images/appliedBg1.svg") bottom
        center/cover;
      z-index: 1;
      min-height: 210px;
    }
    h2 {
      line-height: 1.1;
      padding-top: 12px;
    }
    .percentage {
      font-size: 32px;
      padding-top: 20px;
      line-height: 1;
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        margin-right: 5px;
        height: 22px;
        position: relative;
        top: 1px;
        background: url("../../../assets/images/percentage.svg") no-repeat
          center center/contain;
      }
    }
    .photo {
      position: relative;
      display: inline-block;
      width: 80px;
      height: 80px;
      padding: 0;
      margin-top: -40px;
      margin-bottom: 0;
      opacity: 1 !important;
      &.withPhoto {
        .plus {
          display: none;
        }
      }
      .plus {
        position: absolute;
        width: 26px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        background: $primary-green;
        border-radius: 50%;
        font-size: 24px;
        color: $primary-text-inverted;
        right: 0;
        bottom: 0;
        &:before {
          position: absolute;
          content: "";
          height: 14px;
          width: 2px;
          background: $secondary-background;
          left: 50%;
          top: 50%;
          margin: -7px 0 0 -1px;
          border-radius: 1px;
        }
        &:after {
          position: absolute;
          content: "";
          width: 14px;
          height: 2px;
          background: $secondary-background;
          left: 50%;
          top: 50%;
          margin: -1px 0 0 -7px;
          border-radius: 1px;
        }
      }
      &__container {
        position: relative;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      img {
        border-radius: 50%;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: 140%;
        max-height: 140%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    }
    span {
      line-height: 1.6;
    }
  }
  &__improve {
    margin-top: -8px;
    text-align: left;
    position: relative;
    animation: bounceInUp 1s;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      left: 0;
      bottom: 0;
      height: 82%;
      z-index: 1;
      box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.3);
    }
    &-wrapper {
      position: relative;
      z-index: 3;
      background: url("../../../assets/images/appliedBg2.svg") no-repeat top
        center/cover;
      padding: 42px 20px 20px;
      ul {
        width: 290px;
        margin: 0 auto;
        max-width: 100%;
      }
    }
    h2 {
      text-align: center;
      margin-bottom: 16px;
    }
    li {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 6px;
      }
      .profile__improve-item {
        width: 280px;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        position: relative;
        outline: none;
        font-family: inherit;
        font-weight: inherit;
        text-align: left;
        padding: 0;
        height: 48px;
      }
      h3 {
        font-size: 16px;
        line-height: 1.4;
        color: #000;
      }
      .upload {
        width: 26px;
        height: 26px;
        display: block;
        position: absolute;
        right: 8px;
        top: 4px;
        background: url("../../../assets/images/uploadIcon.svg") no-repeat
          center center/cover;
        transition: all 0.4s ease;
      }
    }
    .completed {
      .upload {
        opacity: 0;
        visibility: hidden;
      }
      .percentage {
        font-size: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border: 3px solid $primary-green;
        background: $primary-green;
        border-radius: 50%;
        margin-left: 13px;
        margin-right: 28px;
        &:before {
          opacity: 1;
        }
      }
    }
    .percentage {
      font-size: 22px;
      margin-right: 18px;
      line-height: 1.3;
      min-width: 30px;
      transition: background 0.3s ease;
      &:before {
        content: "";
        margin: -4px 0 0 -1px;
        width: 6px;
        height: 11px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
  &__contact {
    background-color: $secondary-background;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
    max-width: 100%;
    padding: 16px;
    color: $secondary-text;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.3);
    img {
      margin-right: 23px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    &--details {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      & .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
      }
      &__phone {
        font-size: 14px;
      }
    }
  }
  @media #{$large-screen} {
    &__improve {
      &-wrapper {
        padding: 55px 115px 15px;
      }
    }
  }
}
