@import "../../styles/variables.scss";

.cookie-policy {
  &__container {
    color: $primary-text;
    margin: 40px auto;
    overflow-x: hidden;
    overflow-y: auto;
    @media #{$large-screen} {
      max-width: 900px;
    }
    @media #{$small-screen} {
      max-width: 95vw;
    }
  }
  &__heading {
    font-size: 40px;
  }
  &__sub-heading {
    font-size: 28px;
    color: $primary-text;
    margin-bottom: 10px;
  }
  &__cookie-name {
    font-size: 20px;
    margin-bottom: 5px;
  }
  &__cookie-description {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
