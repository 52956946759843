@import "../../styles/variables.scss";

.notFound__container {
  background: $primary-background;
  min-height: 100vh;
}

.notFound {
  width: 83%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__heading {
    font-family: Montserrat;
    margin-top: 33%;
    font-size: 4rem;
    font-weight: 900;
    color: $primary-green;
    @media #{$large-screen} {
      margin-top: 12%;
    }
  }
  &__title {
    color: $primary-text;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__text {
    color: $secondary-text;
    font-size: 1rem;
    margin-bottom: 10px;
    @media #{$large-screen} {
      margin-bottom: 0px;
    }
  }
  &__button {
    color: $primary-text-inverted;
    background-color: $primary-green;
    border-radius: 5px;
    margin-top: 36px;
  }
  &__support {
    color: $primary-green;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 16px;
  }
  &__support:hover {
    text-decoration: underline;
  }
}
