@import "../../styles/variables.scss";

.connect-with {
  label {
    position: relative;
    display: block;
    cursor: pointer;
    &.checked {
      pointer-events: none;
      cursor: default;
    }
    &:last-child {
      margin-bottom: 0;
    }
    img {
      max-width: 80px;
    }
  }
  .radio {
    top: 0;
    right: 0;
    left: auto;
    width: 50px;
    height: 24px;
    opacity: 0;
    visibility: hidden;
  }
  .radiomark {
    width: 50px;
    height: 24px;
    border: 1px solid #dee2e6;
    background: #dee2e6;
    top: 1px;
    left: auto;
    right: 0;
    margin-right: 0;
    border-radius: 25px;
    z-index: 5;
    cursor: pointer;
    &::before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }
  .radio:checked ~ .radiomark {
    border-color: $primary-green;
    background: white;
    transition: 0.2s 0.2s;
    pointer-events: none;
    &::before {
      left: 28px;
      transition-duration: 0.2s;
    }
    &::after {
      opacity: 1;
      transition: 0.2s 0.2s;
    }
  }
  .radiomark::after {
    width: 5px;
    height: 11px;
    border: solid $primary-green;
    top: 2px;
    left: 50%;
    transform: translate3d(-50%, 0, 0) rotate(45deg);
    background: #fff;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    content: "";
    opacity: 0;
  }
}
