@import "../../styles/variables.scss";

.profile-form-block {
  margin: 0 auto 28px;
  padding: 0;
  @media #{$large-screen} {
    padding: 30px 20px;
    h2 {
      text-align: center;
    }
    .form-field {
      max-width: 330px;
      margin: 0 auto 28px;
    }
  }
  &__data-consent {
    max-width: 330px;
    margin: 0 auto 28px;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $secondary-background;
      border-radius: 5px;
      margin-top: 10px;
      input[type="checkbox"] {
        display: none;
        &:checked {
          ~ .profile-form-block__data-consent--checked-status {
            background: $primary-green;
            border-color: $primary-green;
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
    &--checked-status {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30px;
      height: 30px;
      margin-top: 0;
      border: 3px solid #dddddd;
      border-radius: 50%;
      margin-right: 8px;
      transition: all 0.4s ease;
      &:before {
        content: "";
        margin: -4px 0 0 -1px;
        width: 6px;
        height: 11px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        transition: all 0.4s ease;
      }
    }
  }
  &__tos {
    display: block;
    padding: 0;
    margin: 10px auto 0;
    color: $secondary-text;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    width: 300px;
  }
  &__normal_align {
    text-align: initial;
  }
  &__left {
    float: left;
    margin-top: 3px;
    margin-right: 5px;
    line-height: 10px;
    input[type="checkbox"] {
      height: 16px;
      width: 16px;
    }
  }
}
