@import "../../styles/variables.scss";

.progress-bar {
  margin-bottom: 25px;
  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 9px;
      left: 0;
      height: 3px;
      width: 100%;
      background: linear-gradient(
        to right,
        #47b7b3 0%,
        #57ca65 50%,
        #d8d8d8 50%,
        #d8d8d8 100%
      );
    }
  }
  li {
    display: block;
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 50%;
    border: 3px solid #d8d8d8;
    position: relative;
    z-index: 3;
    &::before {
      position: absolute;
      left: 7px;
      top: 4px;
      width: 5px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  @media #{$large-screen} {
    margin-bottom: 40px;
  }
  &--step-1 {
    li {
      &:nth-of-type(1) {
        background: #47b7b3;
        position: relative;
        border: none;
        &::before {
          content: "";
        }
      }
      &:nth-of-type(2) {
        background: #57ca65;
        border: 6px solid #fff;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      }
    }
  }
  &--step-2 {
    ul {
      &::before {
        background: linear-gradient(to right, #47b7b3 0%, #57ca65 100%);
      }
    }
    li {
      background: #47b7b3;
      border-color: #47b7b3;
      &:nth-of-type(1) {
        position: relative;
        border: none;
        &::before {
          content: "";
        }
      }
      &:nth-of-type(2) {
        position: relative;
        border: none;
        &::before {
          content: "";
        }
      }
      &:nth-of-type(3) {
        background: #54c669;
        border: 6px solid #fff;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      }
    }
    @media #{$large-screen} {
      margin-bottom: 10px;
    }
  }
}
