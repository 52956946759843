@import "../../styles/variables.scss";
// TODO: This still contains a bunch of styles that are used by all popups

.popup-avatar {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  z-index: -1;
  text-align: left;
  transition: all 0.3s ease;
  &__origin-text,
  span {
    display: block;
    font-size: 15px;
    color: $secondary-text;
    margin-top: 5px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 1000;
    .popup__content {
      margin-top: 0;
      opacity: 1;
      z-index: 5;
    }
  }
  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  &__content {
    position: relative;
    background: $primary-background;
    padding: 20px 20px 26px;
    border-radius: 5px;
    overflow: auto;
    z-index: 5;
    max-width: 420px;
    width: 100%;
    opacity: 0;
    transition: margin-top 0.2s ease, opacity 0.4s ease;
  }
  &__title {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: $primary-text-form;
    margin-bottom: 20px;
  }
  // TODO: This should refer to an actual class instead
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px 10px 16px;
    background: $secondary-background;
    border-radius: 5px;
    margin-top: 10px;
    &.popup__no-photo {
      padding-left: 20px;
    }
    .popup__profile-text {
      font-size: 16px;
      line-height: 18px;
      color: $primary-text-form;
      margin-left: 16px;
      &--selected {
        color: $primary-green;
        font-weight: bold;
      }
      span {
        font-weight: normal;
      }
    }
    input[type="radio"] {
      display: none;
      &:checked {
        ~ .popup__checked-status {
          background: $primary-green;
          border-color: $primary-green;
          &:before {
            opacity: 1;
          }
        }
      }
    }
    .popup__checked-status {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30px;
      height: 30px;
      margin-top: 0;
      border: 3px solid #dddddd;
      border-radius: 50%;
      margin-left: auto;
      transition: all 0.4s ease;
      &:before {
        content: "";
        margin: -4px 0 0 -1px;
        width: 6px;
        height: 11px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        transition: all 0.4s ease;
      }
    }
  }
  &__upload-text {
    font-size: 16px;
    line-height: 18px;
    color: $primary-text-form;
  }
  &__profile {
    cursor: pointer;
    &-text {
      margin: 0 5px;
    }
    .popup__profile-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      min-width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 0;
      img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: 140%;
        max-height: 140%;
        background: #dee2e6;
        object-fit: cover;
      }
    }
  }
  &__no-photo {
    cursor: pointer;
    &-text {
      span {
        display: block;
        font-size: 15px;
        color: $secondary-text;
      }
    }
  }
  &__upload {
    &-text {
      width: 100%;
    }
    &-socials {
      width: 100%;
      font-weight: normal;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $secondary-background;
      border-radius: 5px;
      cursor: pointer;
      padding: 16px 27px 16px 20px;
    }
    &-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .popup__upload-icon {
        margin-left: 10px;
        max-width: 30px;
        width: calc(25% - 5px);
      }
    }
    .popup__upload-item {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: $secondary-background;
      border-radius: 5px;
      padding: 16px 27px 16px 20px;
      cursor: pointer;
      &:after {
        display: none !important;
      }
      &.social {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: -50px;
        transition: all 0.4s ease;
        &-li {
          transition-delay: 0.4s;
        }
        &-fb {
          transition-delay: 0.3s;
        }
        &-db {
          transition-delay: 0.2s;
        }
        &-gd {
          transition-delay: 0.1s;
        }
      }
      .popup__upload-icon {
        width: 30px;
        margin-right: 24px;
      }
    }
    &.show {
      .popup__upload {
        &-socials {
          display: none;
        }
        &-item {
          &.social {
            position: relative;
            visibility: visible;
            opacity: 1;
            top: 0;
          }
        }
      }
    }
  }
  &__fb {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    &-status {
      position: absolute;
      width: 30px;
      height: 30px;
      border: 3px solid #dddddd;
      border-radius: 50%;
      top: 10px;
      right: 10px;
      transition: all 0.3s ease;
      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 11px;
        border: solid #fff;
        top: 3px;
        left: 50%;
        transform: translate3d(-50%, 0, 0) rotate(45deg);
        background: $primary-green;
        border-width: 0 3px 3px 0;
        border-radius: 0;
        opacity: 0;
        transition: all 0.3s ease;
      }
    }
    label {
      position: relative;
      width: calc(50% - 10px);
      text-align: center;
      margin-top: 10px;
      padding: 0;
      cursor: pointer;
      input:checked {
        ~ .popup__fb-status {
          background: $primary-green;
          border-color: $primary-green;
          &:before {
            opacity: 1;
          }
        }
      }
      img {
        border-radius: 6px;
        max-width: 100%;
      }
    }
  }
  &__tab {
    display: none;
    &-title {
      width: 100%;
      text-align: center;
    }
    &-button {
      margin: 40px 0 0;
      width: auto;
      text-align: center;
      line-height: 18px;
      font-weight: bold;
      color: $primary-green;
      padding: 0;
    }
    &-text {
      font-size: 14px;
      color: $secondary-text;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    &.active {
      display: block;
      &.popup__fb {
        display: flex;
      }
    }
  }
  &__controlsContainer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  &__cancel {
    color: #bcbcbc;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;
  }
  &__confirm {
    color: $secondary-background;
    background-color: $primary-green;
    border-radius: 5px;
    margin-bottom: 0;
    width: 50%;
  }
  @media #{$large-screen} {
    &__content {
      max-width: 100%;
      width: 652px;
    }
    &__title {
      margin-bottom: 30px;
    }
    &__upload {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-socials {
        display: none;
      }
      div {
        width: calc(50% - 5px);
        > .popup__upload-item {
          width: 100%;
        }
      }
      .popup__upload-item {
        width: calc(50% - 5px);
        &.social {
          position: relative;
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
    label {
      &.popup__no-photo {
        margin-top: 40px;
        padding: 16px 20px;
      }
    }
    &__variants {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
    &__profile {
      width: calc(50% - 5px);
    }
    &__fb {
      justify-content: flex-start;
      margin: 0 -5px;
      label {
        width: 145px;
        margin: 10px 5px 0;
      }
    }

    &-confirmation {
      width: 100%;
      justify-content: flex-start;
      padding-top: 100px;
      text-align: left;

      span {
        font-size: 15px;
        display: flex;
      }
    }
  }
}

.avatar-popup__error {
  color: $error-text-form;
  font-size: 18px;
  text-align: center;
}
