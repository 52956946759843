@import "../../styles/variables.scss";

$cookie-consent-banner-margin: 24px;

.cookie-consent-banner {
  z-index: 1000;
  border-radius: 10px;
  width: 500px;
  max-width: calc(100vw - (#{$cookie-consent-banner-margin} * 2));
  padding: $cookie-consent-banner-margin;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin: $cookie-consent-banner-margin;
  background: $cookie-consent-background;
  color: $primary-text-inverted;

  &__heading {
    font-size: 1.5rem;
    line-height: 2rem;
    color: $cookie-consent-heading-color;
  }
  &__text {
    margin-bottom: 10px;
  }
  &__terms-link {
    color: $primary-text-inverted;
    text-decoration: underline;
  }
  &__button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    button {
      font-family: Montserrat;
      height: 42px;
      border-radius: 21px;
      padding: 10px 24px;
      margin: 0;
      font-size: 1rem;
      &.consent-button {
        background-color: $cookie-consent-button-color;
      }
      &.reject-button {
        font-size: 90%;
        color: $cookie-consent-button-color;
      }
    }
  }

  @media #{$small-screen} {
    width: 100%;
    max-width: initial;
    border-radius: 10px 10px 0px 0px;
    margin: 0;
    padding: 20px;
    &__heading {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    &__text {
      font-size: 0.8rem;
      margin-bottom: 10px;
    }
    &__button-group {
      button {
        font-size: 0.9rem;
        height: 36px;
        border-radius: 18px;
      }
    }
  }
}
