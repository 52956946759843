@import "../../styles/variables.scss";

.form-field {
  margin-bottom: 28px;

  input[value=""] {
    position: relative;
    border-bottom: 1px solid $secondary-text !important;
    background: $secondary-background;
    z-index: 1;
  }

  input,
  select {
    transition: 0.2s;
    display: block;
    width: 100%;
    border: none;
    font-family: inherit;
    border-bottom: 1px solid $secondary-text;
    color: $secondary-text;
    font-size: 16px;
    padding-bottom: 5px;
    background: transparent;

    &::placeholder {
      font-size: 16px;
      color: $secondary-text;
    }
    &.validated {
      color: $primary-green;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        right: 8px;
        top: 0;
        width: 6px;
        height: 10px;
        border: solid $primary-green;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  select {
    color: $primary-text;
  }
  textarea {
    resize: none;
    width: 100%;
    display: block;
    border: none;
    border-radius: 6px;
    color: $primary-text;
    font-size: 16px;
    padding: 5px 8px 5px 8px;
    margin-bottom: 25px;
    font-family: inherit;
    box-sizing: border-box;
    transition: 0.2s;
    background: $primary-background;

    &::placeholder {
      font-size: 16px;
      color: $secondary-text;
    }
  }

  &.validated {
    position: relative;
    input,
    select {
      color: $primary-text;
      border-width: 2px;
      border-color: $primary-green;
    }
    input[type="date"] {
      &:before {
        content: "";
        margin-right: 0;
      }
    }

    &::after {
      position: absolute;
      content: "";
      display: block;
      right: 4px;
      top: 20px;
      width: 4px;
      height: 8px;
      border: solid $primary-green;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  &.candidate-description {
    &::after {
      display: none;
    }
  }
  &.not-valid {
    input,
    textarea {
      color: $error-text-form;
      border-color: $error-text-form;
    }
    &::after {
      display: none;
    }
  }
  &.avatar {
    &::after {
      display: none;
    }
  }

  &__label {
    display: block;
    font-size: 16px;
    line-height: 1.35;
    margin-bottom: 10px;
    &.avatar {
      display: none;
    }
  }
  &__error {
    font-size: 14px;
    color: $error-text-form;
  }
  &__checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        ~ .form-field__checkbox-group__status {
          background: $primary-green;
          border-color: $primary-green;
          &:before {
            opacity: 1;
          }
        }
        ~ .form-field__checkbox-group__text {
          color: $primary-green;
        }
      }
    }
    &__status {
      width: 14px;
      height: 14px;
      background: #ffffff;
      border: 1px solid #979797;
      border-radius: 2px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease;
      &:before {
        content: "";
        margin: -3px 0 0 0px;
        width: 3px;
        height: 7px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        transition: all 0.4s ease;
      }
    }
    &__text {
      color: $primary-text-form;
    }
  }
  &__radio-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        ~ .form-field__radio-group__status {
          background: $primary-green;
          border-color: $primary-green;
          border: 1px solid $primary-green;
          &:before {
            opacity: 1;
          }
        }
        ~ .form-field__radio-group__text {
          color: $primary-green;
        }
      }
    }
    &__status {
      height: 14px;
      width: 14px;
      background: #ffffff;
      border: 1px solid #979797;
      border-radius: 50%;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #fff;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.4s ease;
      }
    }
    &__text {
      color: $primary-text-form;
    }
  }
  &__label-group {
    display: flex !important;
    justify-content: space-between;
    span {
      color: $secondary-text;
      font-size: 13px;
      font-weight: normal;
    }
  }
}
.cv-field {
  margin-bottom: 40px;
  &:after {
    display: none;
  }
  &__button {
    text-align: left;
    color: $primary-text-form;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $primary-background;
    padding: 15px 10px;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;

    @media #{$large-screen} {
      padding: 15px 25px;
    }
    div {
      width: 100%;
    }
  }
  &__social-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    img {
      max-width: 26px;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      @media #{$large-screen} {
        margin-left: 15px;
      }
    }
  }
  &__hint {
    display: block;
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    color: $secondary-text;
  }
  &__file-name {
    padding-right: 35px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.phone-field {
  display: flex;
  margin-bottom: 0 !important;

  .country-code {
    span {
      white-space: nowrap;
    }
    flex: 1;
  }

  .phone {
    flex: 3;
    margin-left: 10px !important;
    input {
      line-height: 20px;
    }
  }

  @media #{$small-screen} {
    flex-wrap: wrap;

    .country-code {
      width: 100%;
      min-width: 100%;
      flex: auto;
    }
    .phone {
      width: 100%;
      min-width: 100%;
      flex: auto;
      margin-left: 0 !important;
    }
  }
}
