@import "../../styles/variables.scss";

.form-screen {
  overflow: hidden;
  color: $primary-text-form;
  background: $primary-background;
  .container {
    max-width: 100%;
  }
  &__form-section {
    position: relative;
    padding: 0 18px;
    max-width: 420px;
    margin: 0 auto;
  }
  &__content {
    top: 0;
    left: 0;
    position: relative;
    z-index: 3;
    opacity: 1;
    transition: opacity 0.4s 0.3s;
    animation: form-items 0.5s ease;
    margin-bottom: 18px;
  }
  @media #{$large-screen} {
    padding: 0;
  }
}
