@import "../../styles/variables.scss";

button {
  &.profile-form-button {
    color: $primary-green;
    padding: 0;
    height: 50px;
    font-size: 16px;
  }
}
