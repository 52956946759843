@import "../../styles/variables.scss";

.redirect-screen {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #fafafa;
    overflow: hidden;
    color: #333;
  }

  &__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    max-width: 800px;
    min-height: 400px;
    margin: auto;
    height: 100vh;
    width: 100vw;
    padding: 1em;
    position: relative;
  }

  &__spinner-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
  }

  &__spinner {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    -webkit-animation: spin 2s cubic-bezier(0.1, 0.4, 0.8, 0.1) infinite;
    animation: spin 2s cubic-bezier(0.1, 0.4, 0.8, 0.1) infinite;
  }

  &__spinner-inner {
    width: 100%;
    height: 50%;
    position: absolute;
    margin-top: 50%;
    background: linear-gradient(
      90deg,
      rgb(220, 220, 220) 50%,
      currentColor 80%
    );

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      margin-top: -50%;
      background: linear-gradient(
        90deg,
        rgb(220, 220, 220) 50%,
        currentColor 80%
      );
      left: 0;
    }
    &::after {
      content: "";
      width: 80%;
      height: 160%;
      position: absolute;
      margin-top: -40%;
      margin-left: 10%;
      background: #fafafa;
      border-radius: 50%;
      left: 0;
    }
  }

  &__logo-wrapper {
    position: absolute;
    height: 90px;
    width: 90px;

    & .title-block__logo {
      @media #{$small-screen} {
        transform: translateY(0%) !important;
        left: 0 !important;
      }
    }
  }

  &__logo {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    background: currentColor;
    border-radius: 5px;
  }

  &__heading {
    margin-top: 2em;
    font-size: 2rem !important;
  }

  &__text {
    margin-top: 2em;
    font-size: 1.2rem;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
