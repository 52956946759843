@import "../../styles/variables.scss";

$logo-size: 90px;
$logo-border: 4px;

.title-block {
  color: $primary-text-inverted;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 32px;
  transition: 0.2s ease-out;
  height: 180px;
  z-index: 2;
  .applied-screen & {
    background: none !important;
  }
  .form-screen &,
  .job-description & {
    background: url("../../../assets/images/defaultBackground.jpg") center
      center/cover;
  }
  .job-description & {
    padding: 28px 0;
  }
  .form-screen & {
    padding: 40px 0;
  }

  .form-screen &,
  .job-description & {
    @media #{$large-screen} {
      position: fixed;
      width: 33vw;
      background: url("../../../assets/images/defaultBackground.jpg") center
        center/cover;
      margin: 0 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      padding: 15px 0px 35px;
      margin: 0;
      transition: none;
      left: 0;
      top: 0;
    }
  }
  &__logo-wrapper {
    -webkit-text-size-adjust: 100%;
    text-align: center;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
    max-width: 420px;
    height: 100%;
    margin: auto;
    padding: 0px 20px;
    @media #{$large-screen} {
      /* justify-content: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column; */
      align-self: flex-end;
      margin: 0;
      padding: 0px;
      padding-top: 70px;
      transform: translateX(50%);
      h1 {
        width: 100%;
      }
    }
    @media #{$small-screen} {
      position: relative;
      width: 100%;
      margin-bottom: -28px;
    }
  }
  &__logo {
    width: $logo-size;
    height: $logo-size;

    background-color: #f9f9f9;
    border: $logo-border solid #f9f9f9;
    border-radius: 10px;
    > div {
      width: calc(#{$logo-size} - (2 * #{$logo-border}));
      height: calc(#{$logo-size} - (2 * #{$logo-border}));
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    @media #{$small-screen} {
      margin: 0;
      position: absolute;
      bottom: 0px;
      left: 16px;
      transform: translateY(50%);
      .form-screen &,
      .applied-screen & {
        display: none;
      }
    }
    @media #{$large-screen} {
      margin-bottom: 40px;
    }
  }
  h1 {
    font-size: 40px;
    font-weight: 900;
    margin: 0 auto 10px;
    padding: 0 10px;
    @media #{$small-screen} {
      font-size: 28px;
    }
  }
  &__desktop {
    @media #{$small-screen} {
      display: none !important;
    }
  }
  &__mobile {
    &.title-block__no-image {
      background: none;
      height: 60px;
    }
    @media #{$large-screen} {
      display: none !important;
    }
  }
}
