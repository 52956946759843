@import "../../styles/variables.scss";

.job-description {
  background: $primary-background;
  text-align: center;
  transition: 0.2s ease-out;
  @media #{$large-screen} {
    padding: 0;
  }
  @media #{$small-screen} {
    min-height: 100vh;
  }
  h2 {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 700;
  }
  b {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }
  p,
  ul {
    margin-bottom: 10px;
  }
  &__container {
    max-width: 420px;
    margin: 0 auto;
    position: relative;
    padding: 0px 20px 20px 20px;
    @media #{$large-screen} {
      min-height: 100vh;
      max-width: 100%;
      display: flex;
      padding: 0px;
    }
  }
  &__content {
    color: $primary-text;
    @media #{$large-screen} {
      max-width: 1000px;
      width: 67vw;
      margin-left: 33vw;
      padding: 80px 5vw 40px;
    }
    @media #{$small-screen} {
      margin-top: 50px;
    }
  }
  &__title {
    font-size: 40px !important;
    margin-bottom: 12px;
    text-align: left;

    @media #{$small-screen} {
      font-family: Montserrat;
      font-size: 20px !important;
    }
    .three & {
      background: linear-gradient(to right, #6d22e9, #e5097a);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__main-content {
    text-align: left;
    margin-bottom: 25px;
    position: relative;
    white-space: pre-wrap;
    transition: all 0.5s ease;
    strong {
      font-weight: bold;
    }
    p {
      line-height: 1.4;
      padding: 4px 0;
    }
    ul {
      white-space: normal;
    }
    li {
      &::before {
        content: "•";
        line-height: 1;
        font-size: 20px;
        padding-right: 10px;
      }
      > p {
        display: inline-block;
      }
    }
  }
  &__text {
    // Set column-width to something very large to fix text cut-off problem
    // https://stackoverflow.com/questions/3220812/css-can-you-prevent-overflow-hidden-from-cutting-off-the-last-line-of-text
    column-width: 2000px;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 1;
    transition: 0.5s ease;
  }
  &__hidden-text-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    height: 1px;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }

  &__toggle-button--expanded,
  &__toggle-button--not-expanded {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    color: $secondary-text;
    font-size: 15px;
    font-weight: bold;
    margin-top: 12px;
    background: none;
    border: none;
    &:hover,
    &:focus {
      opacity: 0.85;
    }
  }
  &__toggle-button--not-expanded::after {
    content: "";
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: 1px;
    width: 11px;
    height: 10px;
    background: url("../../../assets/images/arrow.svg") no-repeat center
      center/contain;
  }
  &__toggle-button--expanded::before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: 1px;
    width: 11px;
    height: 10px;
    transform: rotate(180deg);
    background: url("../../../assets/images/arrow.svg") no-repeat center
      center/contain;
  }
  &__benefits {
    margin-bottom: 40px;
    text-align: left;
    margin-bottom: 90px;
    h2 {
      margin-bottom: 4px;
    }
    li {
      position: relative;
      line-height: 1.4;
      margin-bottom: 10px;
      padding-left: 25px;
      &::before {
        content: "";
        width: 15px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 5px;
        background: url("../../../assets/images/benefit.svg") no-repeat center
          center/contain;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .call-to-action {
    &__desktop {
      @media #{$large-screen} {
        display: block;
      }
      @media #{$small-screen} {
        display: none !important;
      }
    }
    &__mobile {
      @media #{$large-screen} {
        display: none !important;
      }
      @media #{$small-screen} {
        display: block;
      }
    }
  }
  &__apply-buttons {
    .apply-buttons-wrapper {
      max-width: 420px;
      margin: 0 auto;
      position: relative;
      padding: 0px 20px;
      .social-apply-button {
        border: none;
        width: 280px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        padding: 15px 0;
        border-radius: 5px;
        margin: 0px 0px 12px 0px;
        max-width: 100%;
        &:hover,
        &:focus {
          opacity: 0.85;
        }
        @media #{$large-screen} {
          margin: 0px 6px 12px 6px;
        }
        @media #{$small-screen} {
          width: 100%;
        }
      }

      .linkedin {
        background: #2f76b3;
      }
      .facebook {
        background: #415896;
      }
      .manual {
        background: $primary-green;
      }
      .redirect {
        line-height: normal;
        -webkit-appearance: button;
        background: none;
        border: none;
        // width: 280px;
        text-align: center;
        color: #fff;
        padding: 15px 0;
        border-radius: 5px;
        margin-bottom: 12px;
        max-width: 100%;
        &:hover {
          opacity: 0.85;
        }
        &.alt {
          text-align: left;
          padding: 0;
          margin-bottom: 0;
          color: $secondary-text;
          margin-top: 20px;
          &::after {
            content: "";
            display: inline-block;
            margin-left: 8px;
            position: relative;
            top: 1px;
            width: 11px;
            height: 10px;
            background: url("../../../assets/images/arrow.svg") no-repeat center
              center/contain;
          }
        }
      }
    }
    @media #{$large-screen} {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &-fixed {
      @media #{$small-screen} {
        z-index: 2;
        padding: 12px 20px 0px 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: $primary-background;
        width: 100%;
      }

      @media #{$large-screen} {
        &.desktop-overflow {
          z-index: 2;
          padding: 12px 20px 0px 20px;
          position: fixed;
          bottom: 0;
          left: 33vw;
          background-color: #f9f9f9;
          width: 67vw;
          max-width: 1000px;
        }
      }
    }
  }
  .redirect-email-wrapper {
    @media #{$small-screen} {
      position: fixed;
      right: 20px;
      left: 20px;
      bottom: 16px;
    }
    @media (max-width: 350px) {
      right: 8px;
      left: 8px;
    }
  }
  .redirect-email {
    height: 80px;
    background: white;
    box-shadow: 1px 2px 5px rgba(1, 1, 1, 0.1);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }
  .redirect-email-field {
    background: white;
    border: 0;
    flex-grow: 1;
    height: 40px;
    font-size: 18px;
    @media #{$large-screen} {
      min-width: 300px;
    }
    @media #{$small-screen} {
      width: 140px;
      font-size: 16px;
    }
  }
  .redirect-email-symbol {
    margin-left: 24px;
    margin-right: 8px;
    @media #{$small-screen} {
      margin-left: 16px;
      margin-right: 6px;
    }
  }
  .redirect-email-button {
    height: 64px;
    background: #6d22e9;
    border-radius: 50px;
    margin-right: 8px;
    font-size: 18px;
    flex-basis: 250px;

    @media #{$small-screen} {
      font-size: 16px;
      flex-basis: 160px;
    }
  }

  .redirect-email-error {
    text-align: left;
    margin-top: 6px;
  }
  .apply-arrow {
    font-weight: normal;
  }

  .mobile-only {
    @media #{$large-screen} {
      display: none;
    }
  }
  .desktop-only {
    @media #{$small-screen} {
      display: none;
    }
  }
  .job-screen__job-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li {
      @media #{$small-screen} {
        font-size: 14px;
      }
      background: #e0e0e0;
      display: inline-block;
      font-size: 17px;
      margin-right: 12px;
      margin-bottom: 12px;
      padding: 6px 10px 6px 10px;
      border-radius: 4px;
      text-align: center;
      img {
        display: inline-block;
        position: relative;
        height: 17px;
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }
  }
  .job-expired-notice {
    margin: 20px 0;
    text-align: left;
    color: white;
    display: flex;
    justify-content: flex-start;
  }
  .job-expired-notice-text {
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    color: $error-text-form;
    display: inline;
    padding: 6px 16px 6px 0px;
    border-radius: 5px;
  }
}
