@import "./variables.scss";

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff;
  box-shadow: inset 0 0 0 30px #fff;
}

button {
  background: none;
  border: none;
  // width: 280px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding: 15px 0;
  border-radius: 5px;
  margin-bottom: 12px;
  max-width: 100%;
  &:hover {
    opacity: 0.85;
  }
  &.alt {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    color: $secondary-text;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    &::after {
      content: "";
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 1px;
      width: 11px;
      height: 10px;
      background: url("../../assets/images/arrow.svg") no-repeat center
        center/contain;
    }
  }
}

textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

form label {
  position: relative;
}

input[type="date"]:before {
  content: attr(placeholder);
  color: $secondary-text;
  margin-right: 0.5em;
}

input[type="file"],
input[name="avatar"],
.hidden {
  display: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.checkboxmark,
.radiomark {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #979797;
  background: transparent;
  top: 5px;
  left: 0;
  border-radius: 3px;
  position: absolute;
  margin-right: 6px;
  transition: 0.2s ease-in;
}

.radiomark {
  border-radius: 50%;
}

.checkbox:checked ~ .checkboxmark::after,
.radio:checked ~ .radiomark::after {
  display: block;
  content: "";
}

.checkbox:checked ~ .checkboxmark,
.radio:checked ~ .radiomark {
  border-color: $primary-green;
  background: $primary-green;
  transition: 0.2s ease-out;
}

.checkboxmark::after,
.radiomark::after {
  position: absolute;
  left: 4px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.radiomark::after {
  width: 5px;
  height: 5px;
  top: 3px;
  left: 3px;
  background: #fff;
  border-radius: 50%;
}

.container {
  min-height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

.checkbox-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  background: $primary-green;
  border-radius: 50%;
  margin-left: 14px;
  transition: all 0.4s ease;
  &:before {
    content: "";
    margin: -4px 0 0 -1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.4s ease;
  }
}
