@import "../../styles/variables.scss";

.confirmation-title-block {
  color: $primary-text-inverted;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
  transition: 0.2s ease-out;
  background: none !important;
  h1 {
    font-size: 22px;
    margin: 0 auto 10px;
    padding: 0 10px;
    font-family: Montserrat;
  }
  .job-screen__job-info {
    max-width: 420px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  li {
    display: inline-block;
    font-size: 14px;
    margin: 0 12px;
    text-align: center;
    white-space: nowrap;
    flex: auto 1 0;
    &.location {
      flex: 100% 1 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  img {
    display: inline-block;
    position: relative;
    height: 14px;
    margin-right: 5px;
    margin-bottom: 2px;
  }
}
